const measureStartTimes = [
0,
3.3436,
6.8266,
10.3096,
13.8391,
17.3453,
20.81,
24.2416,
27.771,
31.1379,
34.6674,
38.1039,
41.6333,
45.0699,
48.6226,
52.0127,
55.6118,
59.0251,
62.5081,
65.9446,
69.5205,
72.9803,
76.4401,
79.8766,
83.3828,
86.8658,
90.2791,
93.809,
97.292,
100.751,
104.258,
107.741,
111.247,
114.730,
118.166,
121.603,
125.156,
128.639,
132.098,
135.558,
139.087,
142.501,
145.914,
149.444,
152.903,
156.363,
159.869,
163.283,
166.766,
170.30,
173.732,
177.261,
180.744,
184.250,
187.710,
191.216,
194.606,
198.182,
201.596,
205.079,
208.59,
212.021,
215.481,
218.964,
222.470,
226.000,
229.436,
232.943,
236.402,
239.792,
243.299,
246.898,
250.334,
253.724,
257.254,
260.690,
264.336,
267.803,
]

export default measureStartTimes